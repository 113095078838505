import styled from 'styled-components';
import media from 'src/shared/mediaquery';
import ticketBackground from '../../img/ticket_background.gif';

const MintingV2Wrapper = styled.div`
  .minting-wrap {
    ${media.desktop(`
      width: calc(50% - 42px);
      min-width: 620px;
      max-width: 620px;
      margin-top: calc((100vh - 770px) / 2);
    `)}
    ${media.medium(`
      width: calc(50% - 42px);
      min-width: 500px;
      max-width: 500px;
      margin-top: calc((100vh - 770px) / 2);
    `)}
    ${media.mobile(`
      margin: 0 auto 30px;
      width: calc(100% - 40px);
      max-width: 335px;
    `)}
  }
  .minting-box {
    position: relative;
    border: solid 4px #000;
    border-radius: 35px;
    background-color: #fff;
    box-shadow: 0 8px 0 #000;
    padding: 24px 26px;
    box-sizing: border-box;
    z-index: 1;
    ${media.desktop(`
      height: 670px;
    `)}
    ${media.medium(`
      height: 670px;
    `)}
    ${media.mobile(`
      padding: 20px;

    `)}
  }
  .minting-header {
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
    ${media.mobile(`
      flex-direction: column;
      align-items: center;
    `)}

    height:237px;
    width: 100%;

    background-image: url(${ticketBackground});

    overflow: hidden;
    background-size: cover;

    .header_rocket {
      ${media.desktop(`

    margin-top: 50px;
     height:150px;
    `)}
      ${media.medium(`
          margin-top: 65px;
      height:120px;
    `)}
    ${media.mobile(`
    margin-top:90px;
    height:80px
    `)}
    }
  }
  .date-area {
    font-family: 'Baloo 2';
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #009b5c;
    ${media.medium(`
      margin-bottom: 0;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    `)}
    ${media.mobile(`
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    `)}
    .date {
      color: #234d2f;
      padding-left: 12px;
    }
  }
  .account-area {
    display: flex;
    border: 1px solid #afafaf;
    border-radius: 15px;
    height: 42px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    gap: 8px;
    .kikas-logo {
      width: 28px;
      height: 28px;
    }
    .account {
      font-family: 'Baloo 2';
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      display: inline-block;
      line-height: 28px;
      box-sizing: border-box;
      height: 28px;
    }
  }
  .wallet-area {
    position: relative;
    height: 45px;
    .button-background {
      position: absolute;
      width: 202px;
      height: 42px;
      background-color: #000;
      border-radius: 15px;
      bottom: 1px;
      z-index: -1;
      ${media.medium(`
        width: 165px;
      `)}
    }
    .connect-wallet {
      outline: none;
      background-color: #009b5c;
      color: white;
      font-family: 'Baloo 2';
      font-weight: 700;
      font-size: 16px;
      padding-bottom: 4px;
      width: 202px;
      height: 42px;
      border-radius: 15px;
      border: solid 3px #000;
      box-shadow: inset 0px -3px 2px 1px #0a5436;
      cursor: pointer;
      ${media.medium(`
        width: 165px;
      `)}
      :hover {
        margin-top: 2px;
        box-shadow: inset 0px -1px 2px 1px #0a5436;
      }
    }
  }
  .d-day-area {
    font-family: 'Baloo 2';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    background-image: linear-gradient(to right, #009b5c 43%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 20px 1px;
    background-repeat: repeat-x;
    padding-bottom: 24px;
    .days {
      font-weight: 800;
      font-size: 60px;
      line-height: 84px;
    }
    .time {
      font-weight: 700;
      font-size: 40px;
      line-height: 45px;
      color: #515151;
      ${media.mobile(`
        font-size: 35px;
        text-align center;
        margin-bottom: 10px;
      `)}
      .bold {
        width: 50px;
        display: inline-block;
        text-align: end;
        ${media.mobile(`
          width: 35px;
        `)}
      }
      .regular {
        font-weight: 400;
        padding-left: 5px;
        padding-right: 20px;
        ${media.mobile(`
          padding-right: 10px;
        `)}
      }
    }
    .mint-comment {
      margin: 0;
      font-weight: 800;
      font-size: 30px;
      line-height: 42px;
      color: #009b5c;
      ${media.mobile(`
        font-size: 25px;
        line-height: 35px;
        text-align center;
      `)}
    }
  }
  .minting-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;
    ${media.mobile(`
      flex-direction: column;
    `)}
    .info-area {
      font-family: 'Baloo 2';
      font-size: 20px;
      line-height: 32px;
      ${media.mobile(`
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 42px;
      `)}
      .info {
        font-weight: 400;
        color: #515151;
        ${media.mobile(`
          margin-bottom: 8px;
        `)}
      }
      .value {
        font-weight: 800;
        color: #009b5c;
        margin-bottom: 20px;
        ${media.desktop(`
          :not(:last-child) {
            margin-bottom: 12px;
          }
        `)}
        ${media.medium(`
          margin-bottom: 15px;
          :last-child {
            margin-bottom: 32px;
          }
        `)}
        ${media.mobile(`
          margin-bottom: 15px;
        `)}
      }
    }
    .counted-area {
      font-family: 'Baloo 2';
      ${media.desktop(`
        text-align: end;
        place-self: end;
      `)}
      ${media.medium(`
        align-self: end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `)}
      ${media.mobile(`
        align-self: center;
        text-align: center;
      `)}
      .count {
        display: flex;
        height: 60px;
        ${media.desktop(`
          flex-direction: row;
          place-content: end;
        `)}
        ${media.mobile(`
          justify-content: center;
          margin-bottom: 36px;
        `)}
      }
      .count-area {
        position: relative;
        height: 64px;
        .count-background {
          position: absolute;
          width: 60px;
          height: 60px;
          border-radius: 100px;
          background-color: #515151;
          z-index: -1;
          bottom: 1px;
        }
        .count-button {
          border: solid 2px #616161;
          width: 60px;
          height: 60px;
          border-radius: 100px;
          box-shadow: inset 0px -4px 2px 1px #858585;
          cursor: pointer;
          &.active {
            background-color: #fcf38f;
          }
        }
      }
      .current-count {
        width: 85px;
        font-family: 'Baloo 2';
        font-weight: 600;
        font-size: 40px;
        line-height: 64px;
        text-align: center;
        ${media.medium(`
          width: 65px;
        `)}
      }
      .total {
        .static-user {
          font-family: 'Baloo 2';
          font-weight: 700;
          font-size: 20px;
          color: #000;
          line-height: 28px;
          .current {
            font-weight: 800;
            font-size: 35px;
            line-height: 49px;
            ${media.desktop(`
              padding-left: 8px;
            `)}
            ${media.medium(`
              font-size: 32px;
            `)}
            ${media.mobile(`
              display: block;
              line-height: 35px;
            `)}
          }
        }
        .static-all {
          font-family: 'Baloo 2';
          font-weight: 500;
          font-size: 16px;
          color: #515151;
          line-height: 26px;
          margin-bottom: 20px;
          ${media.medium(`
            margin-bottom: 32px;
            text-align: end;
          `)}
          .current {
            color: #009b5c;
          }
        }
      }
    }
  }
  .mint-button-area {
    position: relative;
    height: 84px;
    ${media.medium(`
      height: 74px;
    `)}
    ${media.mobile(`
      height: 64px;
    `)}
    .mint-button-background {
      position: absolute;
      width: 100%;
      background-color: #515151;
      border-radius: 25px;
      z-index: -1;
      bottom: -3px;
      height: 80px;
      ${media.medium(`
        height: 70px;
      `)}
      ${media.mobile(`
        height: 60px;
        bottom: -1px;
      `)}
      &.active {
        background-color: #000;
      }
    }
    .mint-button {
      width: 100%;
      background-color: #eaeaea;
      border-radius: 25px;
      box-shadow: inset 0px -6px 2px 1px #afafaf;
      height: 80px;
      border: solid 3px #515151;
      font-family: 'Baloo 2';
      font-weight: 800;
      font-size: 31px;
      color: #afafaf;
      padding-bottom: 3px;
      cursor: pointer;
      ${media.medium(`
        height: 70px;
      `)}
      ${media.mobile(`
        height: 60px;
        font-size: 22px;
      `)}
      &.active {
        background-color: #009b5c;
        box-shadow: 0px -6px 2px 1px #0a5436 inset;
        border: solid 3px #000;
        color: #fff;
        :hover {
          margin-top: 5px;
          box-shadow: inset 0px -1px 2px 1px #0a5436;
        }
      }
    }
  }
`;

export default MintingV2Wrapper;
