import Welcome from '../welcome/Welcome';
import Minting from '../minting-v2/MintingV2';

import ggnzs from '../../img/ggnz_char.png';

import { MainWrapper } from './MainWrapper';

const Main = (): JSX.Element => {
  return (
    <MainWrapper>
      <div className="main-wrap">
        <Welcome />
        <Minting />
      </div>
      <div className="under-background">
        <div className="character-area desktop">
          <img className="ggnzs" src={ggnzs} alt="ggnz-characters" />
        </div>
      </div>
    </MainWrapper>
  );
};

export default Main;
