export const LARGE_BREAK_POINT = 1440;
export const SMALL_BREAK_POINT = 1024;
export default {
  mobile: (propertyString) =>
    `@media only screen and (max-width: ${SMALL_BREAK_POINT}px) {
      ${propertyString}
    }`,
  medium: (propertyString) => `@media only screen and (min-width: ${
    SMALL_BREAK_POINT + 1
  }px) and (max-width: ${LARGE_BREAK_POINT}px) {
    ${propertyString}
  }`,
  desktop: (propertyString) =>
    `@media only screen and (min-width: ${LARGE_BREAK_POINT + 1}px) {
      ${propertyString}
    }`,
};
