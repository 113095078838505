import styled from 'styled-components';
import media from 'src/shared/mediaquery';

const WelcomeWrapper = styled.div`
  .intro-wrap {
    ${media.desktop(`
      width: calc(50% - 42px);
      min-width: 610px;
      max-width: 620px;
      height: 500px;
      margin-top: calc((100vh - 770px) / 2);
    `)}
    ${media.medium(`
      width: calc(50% - 42px);
      min-width: 500px;
      max-width: 500px;
      height: 500px;
      margin-top: calc((100vh - 770px) / 2);
    `)}
    ${media.mobile(`
      margin: 0 auto;
      width: calc(100% - 40px);
      max-width: 335px;
    `)}
    .scroll-area {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      .character-area {
        text-align: center;
        .ggnzs {
          max-width: 320px;
          width: 100%;
        }
      }
    }
    .welcome-area {
      display: flex;
      flex-direction: row;
      margin: 20px 0 30px;
      ${media.mobile(`
        flex-direction: column;
        align-items: center;
        margin: 40px 0 30px;
      `)}
      .welcome-img {
        width: 397px;
        height: 100%;
        ${media.medium(`
          width: 270px;
        `)}
        ${media.mobile(`
          max-width: 312px;
          width: 100%;
        `)}
      }
      .ggnz-img {
        height: 100%;
        ${media.desktop(`
          width: 214px;
          padding-left: 20px;
        `)}
        ${media.medium(`
          width: 150px;
          padding-left: 20px;
        `)}
        ${media.mobile(`
          max-width: 150px;
          width: 100%;
          padding-top: 10px;
        `)}
      }
    }
    .intro-section-eng {
      font-family: 'Baloo 2';
      margin-top: 12px;
      margin-bottom: 30px;
      ${media.mobile(`
        margin-bottom: 32px;
      `)}
      .eng-title {
        font-size: 32px;
        font-weight: 800;
        line-height: 38px;
        color: #234d2f;
        margin-bottom: 15px;
        ${media.mobile(`
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 12px;
        `)}
      }
      .eng-desc {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: #212121;
        ${media.mobile(`
          font-size: 16px;
          line-height: 21px;
        `)}
      }
    }
    .intro-section-kr {
      ${media.mobile(`
        margin-bottom: 70px;
      `)}
      .kr-title {
        font-family: 'CookieRun-Black';
        font-size: 23px;
        line-height: 32px;
        color: #234d2f;
        margin-bottom: 15px;
        ${media.mobile(`
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 12px;
        `)}
      }
      .kr-desc {
        font-family: 'CookieRun-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: #212121;
        ${media.mobile(`
          font-size: 14px;
          line-height: 21px;
        `)}
      }
    }
  }
`;

export default WelcomeWrapper;
