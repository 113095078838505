const Plus = (): JSX.Element => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 15H23" stroke="#AFAFAF" strokeWidth="5" strokeLinecap="round" />
      <path d="M15.5 7.5L15.5 22.5" stroke="#AFAFAF" strokeWidth="5" strokeLinecap="round" />
    </svg>
  );
};

export default Plus;
