import styled, { keyframes } from 'styled-components';
import media from 'src/shared/mediaquery';

const dotTyping = keyframes`
0% {
    box-shadow: 9974px 0 0 0 #009B5C, 9994px 0 0 0 #009B5C, 10014px 0 0 0 #009B5C;
  }
  16.667% {
    box-shadow: 9974px -10px 0 0 #009B5C, 9994px 0 0 0 #009B5C, 10014px 0 0 0 #009B5C;
  }
  33.333% {
    box-shadow: 9974px 0 0 0 #009B5C, 9994px 0 0 0 #009B5C, 10014px 0 0 0 #009B5C;
  }
  50% {
    box-shadow: 9974px 0 0 0 #009B5C, 9994px -10px 0 0 #009B5C, 10014px 0 0 0 #009B5C;
  }
  66.667% {
    box-shadow: 9974px 0 0 0 #009B5C, 9994px 0 0 0 #009B5C, 10014px 0 0 0 #009B5C;
  }
  83.333% {
    box-shadow: 9974px 0 0 0 #009B5C, 9994px 0 0 0 #009B5C, 10014px -10px 0 0 #009B5C;
  }
  100% {
    box-shadow: 9974px 0 0 0 #009B5C, 9994px 0 0 0 #009B5C, 10014px 0 0 0 #009B5C;
  }
`;

const ResultWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 200;
  }
  .pending {
    width: 235px;
    height: 235px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35px;
    .pending-text {
      font-family: 'Baloo 2';
      font-size: 40px;
      font-weight: 800;
      line-height: 64px;
      text-align: center;
      color: #009b5c;
    }
    .dot-typing {
      position: relative;
      left: -9989px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #009b5c;
      color: #009b5c;
      box-shadow: 9974px 0 0 0 #009b5c, 9994px 0 0 0 #009b5c, 10014px 0 0 0 #009b5c;
      animation: ${dotTyping} 1.5s infinite linear;
    }
  }
  .complete {
    position: absolute;
    width: 600px;
    height: 735px;
    border-radius: 35px;
    ${media.mobile(`
      width: 335px;
      height: 578px;
    `)}
    .complete-text {
      font-family: 'Baloo 2';
      font-size: 45px;
      font-weight: 800;
      line-height: 72px;
      text-align: center;
      color: #009b5c;
      margin-bottom: 8px;
      padding-top: 98px;
      ${media.mobile(`
        font-size: 32px;
        line-height: 48px;
        padding-top: 92px;
      `)}
    }
    .complete-desc {
      font-family: 'Baloo 2';
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      color: #000;
      width: 400px;
      margin: 0 auto;
      ${media.mobile(`
        font-size: 14px;
        line-height: 20px;
        width: 264px;
      `)}
    }
    .ggnzs {
      width: 412px;
      display: block;
      margin: 0 auto;
      padding-top: 32px;
      ${media.mobile(`
        width: 282px;
      `)}
    }
    .go-opensea-area {
      position: relative;
      height: 84px;
      margin: 50px auto 0;
      ${media.mobile(`
        height: 58px;
      `)}
      .button-background {
        position: absolute;
        width: calc(100% - 60px);
        background-color: #000;
        border-radius: 25px;
        z-index: -1;
        bottom: -3px;
        height: 80px;
        left: 50%;
        transform: translateX(-50%);
        ${media.mobile(`
          height: 55px;
          border-radius: 18px;
        `)}
      }
      .go-opensea-button {
        width: calc(100% - 60px);
        border-radius: 25px;
        height: 80px;
        font-family: 'Baloo 2';
        font-weight: 800;
        font-size: 22px;
        padding-bottom: 3px;
        cursor: pointer;
        color: #fff;
        background-color: #009b5c;
        box-shadow: 0px -6px 2px 1px #0a5436 inset;
        border: solid 3px #000;
        display: block;
        margin: 0 auto;
        cursor: pointer;
        ${media.mobile(`
          border-radius: 18px;
          height: 55px;
          font-size: 16px;
          box-shadow: 0px -3px 2px 1px #0a5436 inset;
        `)}
      }
    }
    .go-back {
      border: none;
      font-family: 'Baloo 2';
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #000;
      text-align: center;
      width: calc(100% - 60px);
      background: none;
      height: 80px;
      display: block;
      margin: 0 auto;
      margin-top: 8px;
      cursor: pointer;
      ${media.mobile(`
        height: 55px;
        font-size: 16px;
      `)}
    }
  }
  .failed {
    position: absolute;
    width: 600px;
    height: 735px;
    border-radius: 35px;
    ${media.mobile(`
      width: 335px;
      height: 578px;
    `)}
    .failed-text {
      font-family: 'Baloo 2';
      font-size: 45px;
      font-weight: 800;
      line-height: 72px;
      text-align: center;
      color: #009b5c;
      margin-bottom: 8px;
      padding-top: 98px;
      ${media.mobile(`
        font-size: 32px;
        line-height: 48px;
        padding-top: 92px;
      `)}
    }
    .failed-desc {
      font-family: 'CookieRun-Bold';
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      color: #000;
      width: 400px;
      margin: 0 auto;
      ${media.mobile(`
        font-size: 14px;
        line-height: 20px;
        width: 264px;
      `)}
    }
    .ggnzs {
      width: 412px;
      display: block;
      margin: 0 auto;
      padding-top: 32px;
      ${media.mobile(`
        width: 282px;
      `)}
    }
    .go-back-area {
      position: relative;
      height: 84px;
      margin: 160px auto 0;
      ${media.mobile(`
        height: 58px;
        margin: 120px auto 0;
      `)}
      .button-background {
        position: absolute;
        width: calc(100% - 60px);
        background-color: #000;
        border-radius: 25px;
        z-index: -1;
        bottom: -3px;
        height: 80px;
        left: 50%;
        transform: translateX(-50%);
        ${media.mobile(`
          height: 55px;
          border-radius: 18px;
        `)}
      }
      .go-back-button {
        width: calc(100% - 60px);
        border-radius: 25px;
        height: 80px;
        font-family: 'Baloo 2';
        font-weight: 800;
        font-size: 22px;
        padding-bottom: 3px;
        cursor: pointer;
        color: #fff;
        background-color: #009b5c;
        box-shadow: 0px -6px 2px 1px #0a5436 inset;
        border: solid 3px #000;
        display: block;
        margin: 0 auto;
        cursor: pointer;
        ${media.mobile(`
          border-radius: 18px;
          height: 55px;
          font-size: 16px;
          box-shadow: 0px -3px 2px 1px #0a5436 inset;
        `)}
      }
    }
  }
`;

export default ResultWrapper;
