import Caver, { Contract } from 'caver-js';
import { TRANSACTION_STATE } from 'src/constants/enums/enums';
import { ContractJSONSchema } from 'src/constants/schema/mint_schema';

declare global {
  interface Window {
    klaytn: any;
  }
}
export const init = async (_request: any, _contractJSON: any) => {
  if (window.klaytn.isKaikas) {
    try {
      const accounts = await window.klaytn.enable();
      const networkId = await window.klaytn.networkVersion;

      if (networkId == _contractJSON.chain_id) {
        const caver = new Caver(window.klaytn);
        const contract = new caver.klay.Contract(_contractJSON.abi, _contractJSON.address);
        const isOwner = await handleOwnerChanged(_request, accounts[0]);

        const currentState = {
          isOwner,
          connected: true,
          account: accounts[0],
          caver: caver,
          contract: contract,
          contractJSON: _contractJSON,
        };

        return currentState;
      }
    } catch (err) {
      return {};
    }
  } else {
    const currentState = {
      status: 'Please install Kaikas.',
    };
    return currentState;
  }
};

export const handleOwnerChanged = async (contract: any, account: any): Promise<boolean> => {
  try {
    const result = await contract.methods.owner().call({ from: account });

    if (account === result.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

// export const initListeners = () => {
//   if (window.klaytn) {
//     window.klaytn.on('accountsChanged', () => {
//       window.location.reload();
//     });
//     window.klaytn.on('networkChanged', () => {
//       window.location.reload();
//     });
//   }
// };

export const getSupply = async (contract: Contract): Promise<string> => {
  try {
    const result = await contract.call('totalSupply');

    return result;
  } catch (err) {
    return '0';
  }
};

export const getCost = async (contract: Contract, caver: Caver, total_supply: number): Promise<string> => {
  try {
    const result = await contract.call('getPrice');

    return caver.utils.convertFromPeb(result, 'KLAY');
  } catch (err) {
    return String(total_supply);
  }
};

export const getBalanceof = async (contract: any, address: string) => {
  try {
    const result = await contract.call('balanceOf', address);
    return result;
  } catch (err) {
    return 'err';
  }
};

export const withdraw = async (contract: any, account: string) => {
  try {
    await contract.methods.withdraw().send({ from: account, gas: '2500000' });
    return true;
  } catch (err) {
    return false;
  }
};

export const mint = async (
  contract: any,
  caver: any,
  account: string,
  cost: string,
  amount: number,
  address: string,
  MAGIC_WORD: string,
): Promise<TRANSACTION_STATE> => {
  try {
    const gasPrice = await contract.methods
      .mintBatch(account?.toLowerCase(), amount, MAGIC_WORD)
      .estimateGas({ from: address?.toLowerCase(), value: String(caver.utils.toPeb(Number(cost) * amount)) });

    const encodedCall = await caver.abi.encodeFunctionCall(
      {
        name: 'mintBatch',
        type: 'function',
        inputs: [
          {
            name: 'to',
            type: 'address',
          },
          {
            name: '_mintAmount',
            type: 'uint256',
          },
          { name: '_magicword', type: 'string' },
        ],
      },
      [account, amount, MAGIC_WORD],
    );

    await caver.klay.gasPriceAt('latest').then((d: any) => console.log(caver.utils.hexToNumber(d)));

    await caver.klay.sendTransaction({
      type: 'SMART_CONTRACT_EXECUTION',
      from: account,
      to: address,
      input: encodedCall,
      gas: gasPrice * amount,
      value: String(caver.utils.toPeb(Number(cost) * amount)),
    });

    getSupply(contract);
    return TRANSACTION_STATE.SUCCESS;
  } catch (err) {
    return TRANSACTION_STATE.FAILED;
  }
};
