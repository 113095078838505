export enum TRANSACTION_STATE {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum ADD_OR_SUB {
  ADD = 'add',
  SUB = 'sub',
}
