import styled from 'styled-components';
import Minus from '../svg/Minus';
import Plus from '../svg/Plus';

interface ButtonTextProps {
  text?: string;
  handleClick: () => void;
  enableClick?: boolean;
}

export default function ButtonTextRound({ text, handleClick, enableClick = true }: ButtonTextProps): JSX.Element {
  return (
    <ButtonTextRoundWrapper>
      <div className={`count-background ${enableClick ? 'active' : ''}`} />
      <button
        className={`count-button ${enableClick ? 'active' : ''}`}
        onClick={() => {
          if (enableClick) {
            handleClick();
          }
        }}
      >
        {text === 'add' ? <Plus /> : <Minus />}
      </button>
    </ButtonTextRoundWrapper>
  );
}

const ButtonTextRoundWrapper = styled.div`
  position: relative;
  height: 64px;
  .count-background {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: #616161;
    z-index: -1;
    bottom: 1px;
    &.active {
      background-color: #000;
    }
  }
  .count-button {
    border: solid 2px #616161;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    box-shadow: inset 0px -4px 2px 1px #858585;
    cursor: pointer;
    background-color: #eaeaea;
    &.active {
      background-color: #fcf38f;
      border: solid 2px #000;
      box-shadow: 0px -4px 2px 1px #92892f inset;
      svg {
        filter: brightness(0);
      }
      :hover {
        margin-top: 3px;
        box-shadow: 0px -1px 2px 1px #92892f inset;
      }
    }
  }
`;
