import media from 'src/shared/mediaquery';
import styled from 'styled-components/macro';

export const MainWrapper = styled.div`
  background-color: #7bc8f3;
  .mobile {
    ${media.desktop(`
      display: none !important;
    `)}
    ${media.medium(`
      display: none !important;
    `)}
  }
  .desktop {
    ${media.mobile(`
      display: none !important;
    `)}
  }
  .main-wrap {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    ${media.desktop(`
      justify-content: space-between;
      width: calc(100% - 200px);
      max-width: 1440px;
      height: calc(100vh - 100px);
      padding-top: 95px;
    `)}
    ${media.medium(`
      justify-content: space-around;
      width: calc(100% - 100px);
      max-width: 1440px;
      height: calc(100vh - 100px);
      padding-top: 95px;
    `)}
    ${media.mobile(`
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding-top: 57px;
    `)}
  }

  .under-background {
    height: 100px;
    width: 100%;
    bottom: 0;
    background-color: #1c3247;
    ${media.desktop(`
      position: fixed;
    `)}
    .character-area {
      .ggnzs {
        width: 412px;
        height: auto;
        position: absolute;
        bottom: 6px;
        left: 100px;
      }
    }
  }
`;

export const MainTextWrapper = styled.div``;
