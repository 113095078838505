import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import ggnz_logo_image from '../../img/ggnz-logo.png';

import { HeaderWrapper } from './header.style';

const getWindowDimensions = () => {
  const { innerHeight: height } = window;
  return {
    height,
  };
};

const Header = (): JSX.Element => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [headerHidden, setHeaderHidden] = useState<boolean>(false);
  const focusOnHeader = false;

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    if (windowDimensions.height < 780) {
      handleHeaderVisible(true);
    } else {
      handleHeaderVisible(false);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  const handleHeaderVisible = debounce((value: boolean) => {
    setHeaderHidden(value);
  }, 1000);

  return (
    <HeaderWrapper className={headerHidden && !focusOnHeader ? 'hidden' : ''}>
      <div className="inr flex-b--c">
        <figure className="logo-wrap">
          <a href="http://ggnz.io" className="flex-s--c">
            <img className="logo-img" src={ggnz_logo_image} alt="개구니즈 로고 이미지" />
            <strong className="ggnzLogoTitle">GAEGUNEEZ</strong>
          </a>
        </figure>
        <nav className="gnb-nav desktop" id="menu">
          <ul className="gnb-nav__wrap">
            <li className="gnb-nav__list">
              <a href="http://ggnz.io/">HOME</a>
            </li>
            <li className="gnb-nav__list">
              <a href="http://ggnz.io/#roadmap">ROADMAP</a>
            </li>
            <li className="gnb-nav__list">
              <a href="http://stake.ggnz.io/">STAKE</a>
            </li>
            <li className="gnb-nav__list">
              <a href="http://ggnz.io/gog.html">RARITIES</a>
            </li>
            <li className="gnb-nav__list">
              <a href="http://ggnz.io/#TEAM">TEAM</a>
            </li>
            <li className="gnb-nav__list">
              <a href="http://ggnz.io/#faq">FAQ</a>
            </li>
          </ul>
        </nav>
        <div className="mobile-menu-btn mobile">
          <span className="mobile" />
          <span className="mobile" />
          <span className="mobile" />
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
