import styled from 'styled-components';
import media from '../../shared/mediaquery';

export const HeaderWrapper = styled.header`
  .mobile {
    ${media.desktop(`
      display: none !important;
    `)}
    ${media.medium(`
      display: none !important;
    `)}
  }
  .desktop {
    ${media.mobile(`
      display: none !important;
    `)}
  }
  width: 100%;
  position: fixed;
  left: 0;
  top: 20px;
  z-index: 500;
  height: 80px;
  padding: 0 20px;
  transition: top 0.7s ease-in-out;
  &.hidden {
    top: -100px;
    transition: top 0.7s ease-in-out;
  }
  ${media.mobile(`
    top: 0;
    padding: 0;
    height: 57px;
  `)}
  .inr {
    background-color: #fff;
    border-radius: 50px;
    padding: 10px 30px;
    width: 100%;
    filter: drop-shadow(-2px 8px 11px rgba(0, 0, 0, 0.1));
    max-width: 1480px;
    margin: 0 auto;
    position: relative;
    ${media.mobile(`
      padding: 5px 20px;
      border-radius: 0;
    `)}
  }
  .flex-b--c {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo-wrap {
    .flex-s--c {
      display: flex;
      justify-content: start;
      align-items: center;
      font: inherit;
      color: inherit;
      text-decoration: none;
      font-family: 'Baloo 2', 'cursive';
      letter-spacing: -0.4px;
      width: 100%;
      overflow-x: hidden;
      .logo-img {
        max-width: 100%;
        max-height: 60px;
        vertical-align: top !important;
        ${media.mobile(`
          max-height: 45px;
        `)}
      }
      strong {
        font-size: 30px;
        font-weight: 800;
        line-height: 47px;
        text-align: left;
        color: #009b5c;
        text-transform: uppercase;
        display: inline-block;
        margin-left: 15px;
        ${media.mobile(`
          font-size: 25px;
          margin-left: 5px;
        `)}
      }
    }
  }
  .gnb-nav__wrap {
    display: flex;
    position: relative;
    z-index: 500;
  }

  .gnb-nav__list + .gnb-nav__list {
    padding-left: 25px;
  }

  .gnb-nav__list a {
    font-family: 'Baloo 2';
    text-decoration: none;
    font-size: 19px;
    font-weight: 800;
    line-height: 31px;
    text-transform: uppercase;
    color: #ccc;
    transition: all 0.3s;
    display: inline-block;
    cursor: pointer;
  }
  .gnb-nav__list:hover a {
    color: #009b5c;
  }

  .gnb-nav__list a {
    font-size: 19px;
    font-weight: 800;
    line-height: 31px;
    text-transform: uppercase;
    color: #ccc;
    transition: all 0.3s;
    display: inline-block;
    cursor: pointer;
  }
  .mobile-menu-btn {
    width: 25px;
    height: 18px;
    position: relative;
    z-index: 99999;
    cursor: pointer;
    display: block;
    span {
      width: 100%;
      height: 3px;
      background-color: #444;
      display: block;
      position: absolute;
      right: 0;
      transition: 0.3s ease-in-out;
      border-radius: 3px;
      :nth-child(1) {
        top: 0;
      }
      :nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }
      :nth-child(3) {
        bottom: 0;
      }
    }
  }
`;
