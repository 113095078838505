import { InitialInfoSchema, MintStateSchema } from '../schema/initial_state_schema';
import { SupplyInfoSchema } from '../schema/mint_schema';

import contract from '../../contracts/contract.json';

export const initialInfoState: InitialInfoSchema = {
  connected: false,
  isOwner: false,
  account: '',
};

export const initialMintState: MintStateSchema = {
  loading: false,
  status: `Mint your ${contract.name}`,
  supply: '0',
  cost: '0',
};

export const initialSupplyInfoState: SupplyInfoSchema = {
  isSaleAvailable: true,
  totalSupply: 0,
  remainingSupply: 0,
};
