import welcome from '../../img/welcome.png';
import ggnz from '../../img/ggnz.png';
import ggnzs from '../../img/ggnz_char.png';
import WelcomeWrapper from './WelcomeWrapper';
import { useEffect } from 'react';

const Welcome = (): JSX.Element => {
  useEffect(() => {
    console.log('in welcome component -> this is not allowed rendering only once');
  }, []);

  return (
    <WelcomeWrapper>
      <div className="intro-wrap">
        <div className="scroll-area">
          <h1 className="welcome-area" aria-label="welcome to ggnz!">
            <img className="welcome-img" src={welcome} alt="welcome to" />
            <img className="ggnz-img" src={ggnz} alt="ggnz" />
          </h1>
          <div className="character-area mobile">
            <img className="ggnzs" src={ggnzs} alt="ggnz-characters" />
          </div>
          <div className="intro-section-eng">
            <h2 className="eng-title">
              IT’S TIME TO MEET YOUR <br className="desktop" />
              UNIQUE GAEGUNEEZ NFT.
            </h2>
            <p className="eng-desc">
              There are 5,000 GAEGUNEEZs in all, each randomly generated with one-of-a-kind items found nowhere else in
              the universe except the Earth. Let's have the Guardians of the Planet, GAEGUNEEZ! GAEGUNEEZ can be minted
              on the Klaytn blockchain.
            </p>
          </div>
          <div className="intro-section-kr">
            <h2 className="kr-title">
              세상에 오직 하나뿐인 여러분만의 <br className="desktop" />
              개구니즈 NFT를 만나보세요!
            </h2>
            <p className="kr-desc">
              지구에만 있는 유니크한 아이템들로 랜덤하게 조합된 총 5,000개의 개구니즈가 여러분을 기다리고 있어요! 지구를
              수호하는 개구니즈를 빨리 만나보세요! 개구니즈는 클레이튼 블록체인을 통해 민팅할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </WelcomeWrapper>
  );
};

export default Welcome;
