import dayjs from 'dayjs';
import { useState, useEffect, useRef } from 'react';

import { initialInfoState, initialMintState, initialSupplyInfoState } from '../../constants/constant/initial_state';
import { InitialInfoSchema, MintStateSchema } from '../../constants/schema/initial_state_schema';
import { SupplyInfoSchema } from '../../constants/schema/mint_schema';
import { ADD_OR_SUB, TRANSACTION_STATE } from '../../constants/enums/enums';

import { MAGIC_WORD } from '../../constants/constant/MAGIC_WORD';
import {
  MINTING_END_DATE,
  MINTING_PER_TRANSACTION_MAX,
  MINTING_PER_WALLET_MAX,
  MINTING_START_DATE,
} from '../../constants/constant/MINTING_INFO';

import kikas from '../../img/kikas.png';
import contract from '../../contracts/contract.json';
import { getBalanceof, getCost, getSupply, init, mint } from '../../shared/mint_func';

import PlusMinusButtons from '../button/PlusMinusButtons';
import MintingV2Wrapper from './MintingV2Wrapper';
import Result from '../modal/Result';

import ticketRocket from '../../img/ticket_rocket.gif';

const START_DAY = MINTING_START_DATE;
const END_DAY = MINTING_END_DATE;
let setIntervalForEverySecond: NodeJS.Timer;

const MintingV2 = (): JSX.Element => {
  const [refWidth, setRefWidth] = useState<string | number>(0);

  const ref = useRef<any>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [day, setDay] = useState<string | number>(0);
  const [hour, setHour] = useState<string | number>(0);
  const [min, setMin] = useState<string | number>(0);
  const [sec, setSec] = useState<string | number>(0);

  const [info, setInfo] = useState<InitialInfoSchema>(initialInfoState);
  const [mintInfo, setMintInfo] = useState<MintStateSchema>(initialMintState);
  const [supplyInfo, setSupplyInfo] = useState<SupplyInfoSchema>(initialSupplyInfoState);
  const [userMintAmount, setUserMintAmount] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [transactionState, setTransactionState] = useState<TRANSACTION_STATE>(TRANSACTION_STATE.PENDING);
  const [isMintAvailable, setIsMintingAvailable] = useState<boolean>(false);
  const [userAccount, setAccount] = useState<string>('');
  const [availableMintingTime, setAvailableMintingTime] = useState<boolean>(false);
  const [beforeStartTime, setbeforeStartTime] = useState<boolean>(true);
  const hasMintableAmount = () => Number(info.balanceOf) < 5;

  useEffect(() => {
    getDDay(END_DAY);
  }, []);

  const getDDay = (later: number) => {
    const currentTime = new Date().getTime();
    const setDate = dayjs(later);
    const now = dayjs(currentTime);
    let daysDiff = setDate.diff(now);
    if (beforeStartTime) {
      if (daysDiff <= 0) {
        setbeforeStartTime(false);
        if (hasMintableAmount()) {
          setAvailableMintingTime(true);
        }
      }
    }
    if (calculateAvailabilityMintingTime(currentTime)) {
      daysDiff = dayjs(END_DAY).diff(new Date().getTime());
    }
    const D = Math.floor(daysDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) {
      daysDiff = 0;
      setIsMintingAvailable(false);
      setAvailableMintingTime(false);
    }
    const H = Math.floor((daysDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const M = Math.floor((daysDiff % (1000 * 60 * 60)) / (1000 * 60));
    const S = Math.floor((daysDiff % (1000 * 60)) / 1000);
    setDay(D);
    setHour(H);
    setMin(M);
    setSec(S);
    setAvailableMintingTime(calculateAvailabilityMintingTime(currentTime));
  };

  const calculateAvailabilityMintingTime = (currentTime: number) => {
    const startTime = new Date(START_DAY).getTime();
    const endTime = new Date(END_DAY).getTime();
    return startTime - currentTime <= 0 && endTime - currentTime >= 0;
  };

  const handleClickConnectWallet = async () => {
    const walletInfo = await init('klay_getAccount', contract);
    setInfo((prev) => ({ ...prev, ...walletInfo }));
  };

  const handleClickChangeShowModal = () => {
    setTransactionState(TRANSACTION_STATE.PENDING);
    setShowModal(false);
  };

  // const handleClickWithdraw = () => {
  //   setMintInfo((prev) => ({ ...prev, status: 'Withdrawing...' }));
  //   const promise: Promise<boolean> = Promise.resolve(withdraw(info.contract, info.account));

  //   promise.then((result) => {
  //     setMintInfo((prev) => ({ ...prev, status: result ? 'COMPLETE' : 'FAILED' }));
  //   });
  // };

  const handleClickMint = () => {
    if (!isMintAvailable) {
      return;
    }

    setTransactionState(TRANSACTION_STATE.PENDING);
    setShowModal((prev) => !prev);

    const promise: Promise<TRANSACTION_STATE> = Promise.resolve(
      mint(info.contract, info.caver, info.account, mintInfo.cost, userMintAmount, contract.address, MAGIC_WORD),
    );

    promise
      .then((data) => setTransactionState(data))
      .then(() => {
        if (info.contract && info.caver) {
          Promise.all([
            getSupply(info.contract),
            getCost(info.contract, info.caver, contract.total_supply),
            getBalanceof(info.contract, info.account),
          ]).then((data) => {
            const mintInfo = { supply: data[0], cost: data[1] };
            setMintInfo((prev) => ({ ...prev, ...mintInfo }));
            setInfo((prev) => ({ ...prev, ...{ balanceOf: data[2] } }));
          });
        }
      });
  };

  useEffect(() => {
    const promise = Promise.resolve(init('klay_getAccount', contract));
    promise.then((data) => {
      setInfo((prev) => ({ ...prev, ...data }));
    });
  }, []);

  useEffect(() => {
    if (info.connected && info.caver && info.contract) {
      Promise.all([
        getSupply(info.contract),
        getCost(info.contract, info.caver, contract.total_supply),
        getBalanceof(info.contract, info.account),
      ]).then((data) => {
        const mintInfo = { supply: data[0], cost: data[1] };
        setMintInfo((prev) => ({ ...prev, ...mintInfo }));
        setInfo((prev) => ({ ...prev, ...{ balanceOf: data[2] } }));
        setAccount(info.account.slice(0, 14));
      });
    }
  }, [info.connected]);

  useEffect(() => {
    const supplyInfo = {
      totalSupply: contract.total_supply,
      remainingSupply: Number(mintInfo.supply),
      isSaleAvailable: Number(mintInfo.supply) < contract.total_supply,
    } as SupplyInfoSchema;

    setSupplyInfo(supplyInfo);
  }, [contract, mintInfo.supply]);

  useEffect(() => {
    const isOverMaxPerWallet = Number(info.balanceOf) < MINTING_PER_WALLET_MAX;
    const isUserBalanceAddMintingNumberOverPerWallet =
      Number(info.balanceOf) + userMintAmount <= MINTING_PER_WALLET_MAX;

    const isUserMintingPossible =
      availableMintingTime && isOverMaxPerWallet && isUserBalanceAddMintingNumberOverPerWallet;

    if (info.connected && supplyInfo.isSaleAvailable && isUserMintingPossible) {
      setIsMintingAvailable(true);
    } else {
      setIsMintingAvailable(false);
    }
  }, [info.connected, info.balanceOf, supplyInfo.isSaleAvailable, userMintAmount]);

  useEffect(() => {
    setRefWidth(ref.current.clientHeight);
  }, [refWidth]);

  // useLayoutEffect(() => {
  //   setIntervalForEverySecond = setInterval(() => {
  //     setAvailableMintingTime(calculateAvailabilityMintingTime());
  //     getDDay();
  //   }, 500);
  // }, []);

  return (
    <MintingV2Wrapper>
      {showModal && <Result transactionState={transactionState} onClickChangeShowModal={handleClickChangeShowModal} />}
      <div className="minting-wrap">
        <div className="minting-box" style={{ overflow: 'hidden' }} ref={ref}>
          <div className="minting-header" ref={headerRef}>
            <img
              className="header_rocket"
              src={ticketRocket}
              width="100%"
              style={{
                position: 'relative',
                right: `${
                  ((supplyInfo.totalSupply - supplyInfo.remainingSupply) * 100) / supplyInfo.totalSupply - 30
                }%`,
              }}
            />
          </div>
          <div className="d-day-area" style={{ width: '100%', height: '1px', padding: '0px' }}></div>
          <div className="minting-body">
            <div className="info-area">
              <p className="info">PRICE</p>
              <p className="value">{mintInfo.cost} KLAY</p>
              <p className="info">PER TRANSACTION</p>
              <p className="value">MAX {MINTING_PER_TRANSACTION_MAX}</p>
              <p className="info">PER WALLET</p>
              <p className="value">MAX {MINTING_PER_WALLET_MAX}</p>
            </div>
            <div className="counted-area">
              <div className="count">
                <PlusMinusButtons
                  enableClick={userMintAmount > 1}
                  text={ADD_OR_SUB.SUB}
                  handleClick={() => {
                    setUserMintAmount((prev) => prev - 1);
                  }}
                />
                <div className="current-count">{userMintAmount}</div>
                <PlusMinusButtons
                  enableClick={
                    Number(info.balanceOf) + userMintAmount < MINTING_PER_WALLET_MAX &&
                    userMintAmount < MINTING_PER_TRANSACTION_MAX
                  }
                  text={ADD_OR_SUB.ADD}
                  handleClick={() => setUserMintAmount((prev) => prev + 1)}
                />
              </div>
              <div className="total">
                <p className="static-user">
                  Total Price <span className="current">{userMintAmount * Number(mintInfo.cost)} KLAY</span>
                </p>
                <p className="static-all">
                  <span className="current">{supplyInfo.remainingSupply}</span> / {supplyInfo.totalSupply} Gaeguneez
                  minted
                </p>
              </div>
            </div>
          </div>
          <div className="mint-button-area">
            <div className={`mint-button-background ${isMintAvailable ? 'active' : ''}`} />
            <button className={`mint-button ${isMintAvailable ? 'active' : ''}`} onClick={() => handleClickMint()}>
              MINT
            </button>
          </div>
        </div>
      </div>
    </MintingV2Wrapper>
  );
};

export default MintingV2;
