import ResultWrapper from './ResultWrapper';

import { TRANSACTION_STATE } from 'src/constants/enums/enums';
import ggnzs from '../../img/ggnz_char.png';
import { useEffect } from 'react';
import { OPENSEA_URI } from '../../constants/constant/MINTING_INFO';

interface ResultProps {
  transactionState: TRANSACTION_STATE;
  onClickChangeShowModal: () => void;
}

const Result = ({ transactionState, onClickChangeShowModal }: ResultProps): JSX.Element => {
  useEffect(() => {
    document.body.classList.add('scroll-banded');
    return () => {
      document.body.classList.remove('scroll-banded');
    };
  }, []);
  return (
    <ResultWrapper>
      {transactionState === TRANSACTION_STATE.PENDING && (
        <div className="modal pending">
          <p className="pending-text">pending</p>
          <div className="stage">
            <div className="dot-typing" />
          </div>
        </div>
      )}
      {transactionState === TRANSACTION_STATE.SUCCESS && (
        <div className="modal complete">
          <p className="complete-text">Congratulations!</p>
          <p className="complete-desc">
            Your NFT has been minted successfully! Check and refresh your wallet as it might not appear immediately!
          </p>
          <img src={ggnzs} alt="ggnz characters" className="ggnzs" />
          <div className="go-opensea-area">
            <div className="button-background" />
            <button
              className="go-opensea-button"
              onClick={() => {
                window.open(OPENSEA_URI, '_system');
                onClickChangeShowModal();
              }}
            >
              오픈씨에서 확인하기
            </button>
          </div>
          <button className="go-back" onClick={onClickChangeShowModal}>
            민트페이지로 돌아가기
          </button>
        </div>
      )}
      {transactionState === TRANSACTION_STATE.FAILED && (
        <div className="modal failed">
          <p className="failed-text">Oops...!</p>
          <p className="failed-desc">
            알 수 없는 오류가 발생했어요. <br /> 다시 한 번 시도해 주세요!
          </p>
          <img src={ggnzs} alt="ggnz characters" className="ggnzs" />
          <div className="go-back-area">
            <div className="button-background" />
            <button className="go-back-button" onClick={onClickChangeShowModal}>
              돌아가기
            </button>
          </div>
        </div>
      )}
    </ResultWrapper>
  );
};

export default Result;
